import { FormGroup } from '@angular/forms';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import {
  MeetingDetailsDTO,
  MeetingFormDTO,
  MeetingListDTO,
} from '../dto/meeting.dto';
import { Injectable } from '@angular/core';
import { MasterConversion } from '../../../../shared/conversion/master.conversion';

@Injectable({
  providedIn: 'root',
})
export class MeetingConversion
  implements
    MainConversionInterface<MeetingListDTO, MeetingFormDTO, MeetingDetailsDTO>
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion,
    private masterConv: MasterConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): MeetingListDTO[] {
    if (!response) {
      return [];
    }

    const result = response.map((item) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        description: item.description,
        meeting_id: item.meeting_id,
        meeting_type_title: item[`meeting_type_title_${this.lang}`],
        meeting_frequency_title: item[`meeting_frequency_title_${this.lang}`],
        title: item.title,
        location: item.location,
        date: item.date,
        start_time: item.start_time,
        end_time: item.end_time,
        start_time_end_time: `${item.start_time} - ${item.end_time}`,
        organizer_user: this.userConv.resToUserMinimalDTO(
            item,
            'organizer_user'
          ),
        created_at: item.created_at,
        updated_at: item.updated_at,
        meeting_status_title: item[`meeting_status_title_${this.lang}`],
        created_user: this.userConv.resToUserMinimalDTO(item,'created_user'),
        is_public: item.is_public,
      };
    });
    return result;
  }

  formGroupToForm(formGroup: FormGroup): MeetingFormDTO {
    return {
      id: formGroup.get('id')?.value,
      title: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      meeting_id: formGroup.get('MeetingId')?.value,
      meeting_type_id: formGroup.get('MeetingType')?.value,
      // meeting_frequency_id: formGroup.get('MeetingFrequency')?.value,
      location: formGroup.get('Location')?.value,
      date: formGroup.get('Date')?.value,
      start_time: formGroup.get('StartTime')?.value ? `${formGroup.get('StartTime')?.value}:00` : '',
      end_time: formGroup.get('EndTime')?.value ? `${formGroup.get('EndTime')?.value}:00` : '',
      organizer_id: formGroup.get('Organizer')?.value,
      meeting_status_id: formGroup.get('MeetingStatus')?.value,
      user_ids: formGroup.get('Participants')?.value,
   
    };
  }
  resToForm(response: any): any {
    return {
      id: response.id,
      Title: response.title,
      Description: response.description,
      MeetingId: response.meeting_id,
      MeetingType: response.meeting_type?.id,
      // MeetingFrequency: response.meeting_frequency?.id,
      Location: response.location,
      Date: response.date,
      StartTime: response.start_time,
      EndTime: response.end_time,
      Organizer: response.organizer?.id,
      MeetingStatus: response.meeting_status?.id,
      IsPublic: response.is_public,
      Participants: response.participants.map((participant: any) => participant.user_id),

    };
  }

  resToDetails(response: any): MeetingDetailsDTO {
    return {
      id: response.id,
      reference_number: response.reference_number,
      title: response.title,
      description: response.description,
      meeting: response.meeting,
      meeting_type: this.masterConv.resToDetails(response.meeting_type),
      meeting_frequency: response.meeting_frequency ? this.masterConv.resToDetails(response.meeting_frequency) : null,
      location: response.location,
      date: response.date,
      start_time: response.start_time,
      end_time: response.end_time,
      organizer: this.userConv.resObjToUserMinimalDTO(response.organizer),
      meeting_status: this.masterConv.resToDetails(response.meeting_status),
      participants: response.participants.map((participant: any) => {
        return this.userConv.resObjToUserMinimalDTO(participant.user)
      }),
      created_by: this.userConv.resObjToUserMinimalDTO(response.created_by),
      created_at: response.created_at,
      updated_by: this.userConv.resObjToUserMinimalDTO(response.updated_by),
      updated_at: response.updated_at,
    };
  }

}
